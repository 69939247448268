<template>
    <div>
        <y-panel-grid :service="$services.Stock.pricesGrid" />
    </div>
</template>

<script>

    // Mixins
    import GridPageMixin from '@/mixins/GridPage';

    export default {

        name: 'PaymentAccountsList',

        mixins: [GridPageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('stocks-list', this.$t('breadcrumbs.stock.list')),
                this.$bc(this.$t('breadcrumbs.stock.prices')),
            ];
        },
    };
</script>
